import React, { useState } from 'react';
import { Button, Snackbar, CircularProgress } from '@mui/material';
import { Share } from 'lucide-react';

const ShareButton = ({ onShare, isLoading }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleClick = async () => {
    if (isLoading) return;

    const message = await onShare();
    if(message !== "") {
        setMessage(message);
        setOpen(true);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <Share />}
        onClick={handleClick}
        disabled={isLoading}
      >
        {isLoading ? 'Sharing...' : 'Share Rankings'}
      </Button>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        message={message}
      />
    </>
  );
};

export default ShareButton;
import React, { useState, useEffect, useRef } from 'react';
import { TextField, InputAdornment, Button, Popper, Paper, List, ListItem, ListItemText, ClickAwayListener } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';

const SearchBar = ({ 
    searchInputValue, 
    onSearch, 
    onInputChange, 
    onClearSearch,
    fetchSuggestions = async (value) => {
        // Default implementation for backwards compatibility
        const response = await axios.post('/api/games/solo?page=0&size=5', {
            nameSearchTerm: value
        });
        return response.data.content.map(game => game.name);
    }
}) => {
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const searchRef = useRef(null);

    useEffect(() => {
        const getSuggestions = async () => {
            if (searchInputValue.length < 2) {
                setSuggestions([]);
                return;
            }

            try {
                const suggestionResults = await fetchSuggestions(searchInputValue);
                setSuggestions(suggestionResults);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
                setSuggestions([]);
            }
        };

        const debounceTimer = setTimeout(getSuggestions, 300);
        return () => clearTimeout(debounceTimer);
    }, [searchInputValue, fetchSuggestions]);

    const handleSuggestionClick = (suggestion) => {
        onInputChange(suggestion);
        setShowSuggestions(false);
        onSearch(suggestion);
    };

    const handleInputChange = (event) => {
        onInputChange(event.target.value);
        setShowSuggestions(true);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onSearch(searchInputValue);
            setShowSuggestions(false);
        }
    };

    return (
        <div ref={searchRef}>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Search Games"
                value={searchInputValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="contained"
                                onClick={() => {
                                    onSearch(searchInputValue);
                                    setShowSuggestions(false);
                                }}
                                sx={{ minWidth: 'unset', px: 2, mr: 1 }}
                            >
                                <SearchIcon />
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    onClearSearch();
                                    setShowSuggestions(false);
                                }}
                                sx={{ minWidth: 'unset', px: 2 }}
                            >
                                <ClearIcon />
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />
            <Popper 
                open={showSuggestions && suggestions.length > 0} 
                anchorEl={searchRef.current}
                placement="bottom-start"
                style={{ width: searchRef.current?.clientWidth, zIndex: 1300 }}
            >
                <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
                    <Paper elevation={3}>
                        <List>
                            {suggestions.map((suggestion, index) => (
                                <ListItem 
                                    button 
                                    key={index} 
                                    onClick={() => handleSuggestionClick(suggestion)}
                                    dense
                                >
                                    <ListItemText primary={suggestion} />
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </div>
    );
};

export default SearchBar;
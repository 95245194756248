import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Autocomplete, TextField, CircularProgress, Card, CardContent, Typography, Box, Chip } from '@mui/material';
import { debounce } from 'lodash';
import StarIcon from '@mui/icons-material/Star';
import ScaleIcon from '@mui/icons-material/Scale';
import GameDetailsOverlay from "./GameDetailsOverlay.js";
import Utils from './Utils.js';

const GameRecommendations = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState([]);
  const [selectedGame, setSelectedGame] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [isLoadingGameDetails, setIsLoadingGameDetails] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const gameId = searchParams.get('gameId');
    if (gameId) {
      fetchGameAndRecommendations(gameId);
    } else {
      // Reset the state when there's no gameId in the URL
      resetState();
    }
  }, [location]);

  const resetState = () => {
    setSearchTerm('');
    setOptions([]);
    setSelectedGame(null);
    setRecommendations([]);
    setIsLoading(false);
    setOverlayOpen(false);
    setSelectedRecommendation(null);
    setIsLoadingGameDetails(false);
  };

  const fetchGameAndRecommendations = async (gameId) => {
    setIsLoading(true);
    try {
      const [gameResponse, recommendationsResponse] = await Promise.all([
        axios.get(`/api/games/${gameId}`),
        axios.get(`/api/games/recommendations/${gameId}`)
      ]);
      setSelectedGame(gameResponse.data);
      setRecommendations(recommendationsResponse.data.slice(0, 25));
    } catch (error) {
      console.error('Error fetching game and recommendations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSuggestions = debounce(async (input) => {
    if (input.length < 2) {
      setOptions([]);
      return;
    }
    try {
      const response = await fetch('/api/games/solo?page=0&size=5', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          nameSearchTerm: input
        }),
      });
      const data = await response.json();
      setOptions(data.content);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  }, 300);

  const handleSearchChange = (event, newValue, reason) => {
    if (reason === 'input') {
      setSearchTerm(newValue);
      fetchSuggestions(newValue);
    }
  };

  const handleGameSelect = (event, value) => {
    if (value) {
      setSelectedGame(value);
      setSearchTerm('');
      navigate(`/recommendations?gameId=${value.id}`);
    }
  };

  const handleRecommendationClick = async (game) => {
    setIsLoadingGameDetails(true);
    try {
      const response = await axios.get(`/api/games/${game.id}`);
      setSelectedRecommendation(response.data);
      setOverlayOpen(true);
    } catch (error) {
      console.error('Error fetching game details:', error);
    } finally {
      setIsLoadingGameDetails(false);
    }
  };

  const handleOverlayClose = () => {
    setOverlayOpen(false);
    setSelectedRecommendation(null);
  };

  const ratingColor = (game) => game.soloRating ? 
    Utils.getRatingColor(game.soloRating.toFixed(1)) : 
    'bg-gray-500 text-white';

  return (
    <div className="container mx-auto px-4 py-8">
      <Box className="bg-white shadow-sm rounded-lg overflow-hidden mb-8 p-6">
        <h2 className="text-2xl font-semibold text-slate-800 mb-4">Game Recommendations (beta)</h2>
        <Typography variant="body1" component="p" className="text-gray-700 mb-4">
          Search for a game to get recommendations based on attributes like theme, mechanics, and weight. 
          Games with a higher solo ranking will be prioritized in the recommendations.
        </Typography>
      </Box>

      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.name}
        inputValue={searchTerm}
        onInputChange={handleSearchChange}
        onChange={handleGameSelect}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for a game"
            variant="outlined"
            fullWidth
          />
        )}
        className="mb-8"
        disableClearable
        forcePopupIcon={false}
        blurOnSelect
      />

      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : recommendations.length > 0 && selectedGame ? (
        <>
          <Typography variant="h5" component="h3" className="font-bold mb-4">
            Similar games to: {selectedGame.name}
          </Typography>
          <div className="space-y-4 mt-4">
            {recommendations.map((game) => (
              <Card 
                key={game.id} 
                className="w-full cursor-pointer hover:shadow-lg transition-shadow duration-200"
                onClick={() => handleRecommendationClick(game)}
              >
                <CardContent className="flex items-start">
                  <div className="w-[100px] h-[100px] mr-4 bg-gray-200 flex justify-center items-center rounded-md flex-shrink-0">
                    <img src={game.thumbnail} alt={game.name} className="max-w-[90px] max-h-[90px] object-contain" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="flex justify-between items-start mb-2">
                      <h2 className="text-lg font-bold text-gray-800 pr-2">{game.name}</h2>
                      <div className="bg-blue-600 text-white px-3 py-1 rounded-md text-sm font-bold">
                        #{game.soloRank}
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center mb-2">
                      <div className={`${ratingColor(game)} px-2 py-1 rounded-md flex items-center mr-2 mb-1`}>
                        <StarIcon fontSize="small" className="mr-1" />
                        <span className="font-bold">{game.soloRating ? game.soloRating.toFixed(1) : 'N/A'}</span>
                      </div>
                      <div className="flex items-center text-sm text-gray-600 mr-2 mb-1">
                        <ScaleIcon fontSize="small" className="mr-1" />
                        <span>Weight: {game.avgWeight ? game.avgWeight.toFixed(1) : 'N/A'}</span>
                      </div>
                    </div>
                    <div>
                      <Typography variant="subtitle2" className="mb-1">Shares the following tags:</Typography>
                      <div className="flex flex-wrap gap-1">
                        {[...game.commonFeatures.categories, ...game.commonFeatures.mechanics, ...game.commonFeatures.families].map((tag, index) => (
                          <Chip key={index} label={tag} size="small" />
                        ))}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <Box className="text-center py-8">
          <Typography variant="h6" className="text-gray-600">
            Search for a game above to see recommendations
          </Typography>
        </Box>
      )}
      {isLoadingGameDetails && (
        <Box 
          position="fixed" 
          top="50%" 
          left="50%" 
          sx={{ transform: 'translate(-50%, -50%)' }}
        >
          <CircularProgress />
        </Box>
      )}
      <GameDetailsOverlay
        game={selectedRecommendation}
        open={overlayOpen}
        onClose={handleOverlayClose}
      />
    </div>
  );
};

export default GameRecommendations;
class Utils {
    static getRatingColor(rating) {
        if (rating >= 8.0) return 'bg-green-600 text-white';
        if (rating >= 7.5) return 'bg-green-600 text-white';
        if (rating >= 7.0) return 'bg-green-500 text-white';
        if (rating >= 6.5) return 'bg-green-400 text-black';
        if (rating >= 6.0) return 'bg-yellow-500 text-black';
        if (rating >= 5.5) return 'bg-yellow-400 text-black';
        if (rating >= 5.0) return 'bg-orange-400 text-black';
        if (rating >= 4.5) return 'bg-red-500 text-white';
        return 'bg-red-600 text-white';
      }; 
}

export default Utils;
import React from 'react';
import GameList from './GameList.js';
import { Link as RouterLink } from 'react-router-dom';
import Metadata from './Metadata.js';

const WelcomeSection = () => (
  <section className="bg-white shadow-sm rounded-lg overflow-hidden mb-8 p-6">
    <h2 className="text-2xl font-semibold text-slate-800 mb-4">Welcome to Solo Sleuth!</h2>
    <p className="text-gray-700">
      Whether you're a seasoned solo gamer or just starting your journey, 
      Solo Sleuth is here to help you find your next favorite game. Check out the <RouterLink to="/rankings" className="text-blue-600 hover:underline">rankings</RouterLink> to 
      find the best board games with filters for mechanics, weight, and more.
    </p>
  </section>
);


const TopGamesSection = () => (
  <section className="mb-8">
    <h2 className="text-2xl font-semibold text-slate-800 mb-4">Top Solo Games of All Time</h2>
    <GameList apiUrl='/api/games/solo' itemsPerPage={5} showDescription={true} showPagination={false}/>
    <div className="mt-6 text-center">
      <RouterLink to="/rankings" className="inline-block bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300">
        See More Top Solo Games
      </RouterLink>
    </div>
  </section>
);

const Home = () => (
  <>
  <Metadata 
    title="Solo Sleuth - Find Your Next Favorite Solo Board Game"
    description="Discover the best solo board games with Solo Sleuth. Browse rankings, get recommendations, and find your perfect game."
    canonicalUrl="https://solosleuth.com"
  />
  <div className="container mx-auto px-4 py-8">
    <WelcomeSection />
    <TopGamesSection />
  </div>
  </>
);

export default Home;
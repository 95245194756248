export const FILTER_TYPES = {
    MECHANICS: {
      key: 'mechanics',
      label: 'Mechanics',
      endpoint: '/api/games/mechanics',
      responseTransform: (data) => data
    },
    CATEGORIES: {
      key: 'categories',
      label: 'Categories',
      endpoint: '/api/games/categories',
      responseTransform: (data) => data
    },
    FAMILIES: {
      key: 'families',
      label: 'Families',
      endpoint: '/api/games/families',
      responseTransform: (data) => data.content.map(f => f.name),
      searchEndpoint: '/api/games/families',
      searchTransform: (data) => data.content.map(f => f.name)
    },
    PUBLISHERS: {
      key: 'publishers',
      label: 'Publishers',
      endpoint: '/api/games/publishers',
      responseTransform: (data) => data.content.map(p => p.name),
      searchEndpoint: '/api/games/publishers',
      searchTransform: (data) => data.content.map(p => p.name)
    },
    DESIGNERS: {
      key: 'designers',
      label: 'Designers',
      endpoint: '/api/games/designers',
      responseTransform: (data) => data.content.map(d => d.name),
      searchEndpoint: '/api/games/designers',
      searchTransform: (data) => data.content.map(d => d.name)
    }
  };
  
  // Helper function to generate initial filter state
  export const generateInitialFilterState = () => ({
    nameSearchTerm: '',
    minAvgWeight: null,
    maxAvgWeight: null,
    minPlayingTime: null,
    maxPlayingTime: null,
    minPlayers: null,
    years: [],
    ...Object.fromEntries(
      Object.values(FILTER_TYPES).map(type => [type.key, []])
    )
  });
  
  // Helper function for URL parameter handling
  export const handleFilterParams = {
    encode: (filters, params = new URLSearchParams()) => {
      // Clear all existing filter params
      Object.values(FILTER_TYPES).forEach(type => {
        params.delete(type.key);
      });
  
      // Add array-based filters
      Object.values(FILTER_TYPES).forEach(type => {
        filters[type.key].forEach(value => params.append(type.key, value));
      });
  
      // Add numeric filters
      if (filters.minAvgWeight !== null) params.set('minWeight', filters.minAvgWeight);
      if (filters.maxAvgWeight !== null) params.set('maxWeight', filters.maxAvgWeight);
      if (filters.minPlayers !== null) params.set('minPlayers', filters.minPlayers);
      if (filters.minPlayingTime) params.set('minPlayingTime', filters.minPlayingTime);
      if (filters.maxPlayingTime) params.set('maxPlayingTime', filters.maxPlayingTime);
      if (filters.nameSearchTerm) params.set('search', filters.nameSearchTerm);
  
      // Add years
      filters.years.forEach(year => params.append('years', year));
  
      return params;
    },
  
    decode: (searchParams) => {
      const decodeParam = (param) => {
        if (!param) return param;
        try {
          return decodeURIComponent(param).replace(/\+/g, ' ');
        } catch (error) {
          return param;
        }
      };
  
      const filters = generateInitialFilterState();
  
      // Decode array-based filters
      Object.values(FILTER_TYPES).forEach(type => {
        filters[type.key] = searchParams.getAll(type.key).map(decodeParam);
      });
  
      // Decode numeric and other filters
      filters.minAvgWeight = searchParams.get('minWeight') ? Number(searchParams.get('minWeight')) : null;
      filters.maxAvgWeight = searchParams.get('maxWeight') ? Number(searchParams.get('maxWeight')) : null;
      filters.nameSearchTerm = decodeParam(searchParams.get('search')) || '';
      filters.minPlayingTime = decodeParam(searchParams.get('minPlayingTime')) || '';
      filters.maxPlayingTime = decodeParam(searchParams.get('maxPlayingTime')) || '';
      filters.minPlayers = searchParams.get('minPlayers') ? Number(searchParams.get('minPlayers')) : null;
      filters.years = searchParams.getAll('years').map(decodeParam);
  
      return filters;
    }
  };
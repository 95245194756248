import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';

const OptionsDialog = ({ open, onClose, options, label, onOptionSelect }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>{`All ${label}`}</DialogTitle>
            <DialogContent>
                <List>
                    {options.map((option) => (
                        <ListItem
                            button
                            key={option}
                            onClick={() => onOptionSelect(option)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <ListItemText primary={option} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
};

export default OptionsDialog;
import React, { useState, useCallback, useMemo } from 'react';
import { Autocomplete, TextField, Chip, Button } from '@mui/material';
import debounce from 'lodash/debounce.js';

const AutocompleteInput = ({
    options,
    popularOptions,
    label,
    filterKey,
    filters,
    handleFilterChange,
    removeFilter,
    onDialogOpen,
    showBrowseAllOptions = true,
    onSearch
}) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const debouncedSearch = useCallback((value) => {
        if (onSearch) {
            setLoading(true);
            onSearch(value).finally(() => setLoading(false));
        }
    }, [onSearch, setLoading]);
      
    const debouncedSearchWithDelay = useMemo(
        () => debounce(debouncedSearch, 300),
        [debouncedSearch]
    );

    const handleInputChange = (event, newInputValue) => {
        // For years, only allow numbers and limit to 4 digits
        if (filterKey === 'years') {
            const numericInput = newInputValue.replace(/[^0-9]/g, '').slice(0, 4);
            setInputValue(numericInput);
            debouncedSearchWithDelay(numericInput);
        } else {
            setInputValue(newInputValue);
            debouncedSearchWithDelay(newInputValue);
        }
    };

    const handleChange = (event, newValue) => {
        if (filterKey === 'years') {
            // Filter out non-numeric values and only accept 4-digit years
            const formattedYears = newValue
                .map(val => {
                    const yearStr = typeof val === 'string' ? val : val?.label || val;
                    const numericYear = yearStr.replace(/[^0-9]/g, '');
                    return numericYear.length === 4 ? numericYear : null;
                })
                .filter(Boolean); // Remove null values
            
            handleFilterChange(filterKey, formattedYears);
        } else {
            handleFilterChange(filterKey, newValue);
        }
    };

    const filterOptions = (options, { inputValue }) => {
        if (inputValue === '') {
            return showBrowseAllOptions 
                ? [...popularOptions, { inputValue: 'browse', label: 'Browse all options...' }]
                : options;
        }

        let filtered;
        if (filterKey === 'years') {
            // For years, show the input value as an option only if it's a valid 4-digit year
            const numericInput = inputValue.replace(/[^0-9]/g, '');
            filtered = options.filter((option) => {
                const optionStr = typeof option === 'string' ? option : option.label;
                return optionStr.includes(numericInput);
            });
            
            // Only add the input value as an option if it's exactly 4 digits
            if (numericInput.length === 4) {
                const inputExists = filtered.some(opt => 
                    (typeof opt === 'string' ? opt : opt.label) === numericInput
                );
                if (!inputExists) {
                    filtered.unshift(numericInput);
                }
            }
        } else {
            filtered = options.filter((option) =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
        }

        if (showBrowseAllOptions && filtered.length > 5) {
            return [
                ...filtered.slice(0, 5),
                { inputValue, label: `View all ${filtered.length} results...` },
            ];
        }
        return filtered;
    };

    const getOptionLabel = (option) => {
        if (typeof option === 'string') return option;
        if (option.inputValue) return option.inputValue;
        return option.label;
    };

    const renderOption = (props, option) => {
        if (showBrowseAllOptions && (option.inputValue === 'browse' || option.label?.startsWith('View all'))) {
            return (
                <Button
                    {...props}
                    onClick={() => {
                        onDialogOpen(filterKey);
                        handleClose();
                    }}
                    fullWidth
                    style={{ justifyContent: 'flex-start', textTransform: 'none' }}
                >
                    {option.label}
                </Button>
            );
        }
        return <li {...props}>{option.label || option}</li>;
    };

    const getHelperText = () => {
        if (filterKey === 'years') {
            return 'Enter a 4-digit year or select from options';
        }
        if (!showBrowseAllOptions) {
            return 'Start typing to search';
        }
        return 'Type to search or click to browse all options';
    };

    return (
        <Autocomplete
            multiple
            options={options}
            value={filters[filterKey]}
            onChange={handleChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            onOpen={handleOpen}
            onClose={handleClose}
            open={open}
            loading={loading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={getHelperText()}
                    inputProps={{
                        ...params.inputProps,
                        ...(filterKey === 'years' ? {
                            pattern: '[0-9]*',
                            maxLength: 4,
                        } : {})
                    }}
                />
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        key={option}
                        label={option}
                        {...getTagProps({ index })}
                        onDelete={() => removeFilter(filterKey, option)}
                    />
                ))
            }
            filterOptions={filterOptions}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) =>
                (option.label && option.label === value) || option === value
            }
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            freeSolo
            renderOption={renderOption}
        />
    );
};

export default AutocompleteInput;
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Grid, Typography, Slider, FormControl, InputLabel, Select, MenuItem, TextField, Checkbox, FormControlLabel } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAutocomplete from "./FilterAutocomplete.js";
import OptionsDialog from './OptionsDialog.js';
import debounce from 'lodash.debounce';
import { useSearchParams } from 'react-router-dom';
import { FILTER_TYPES } from './filterConfig.js';

const weightOptions = [
    { value: 1, label: 'Light' },
    { value: 2, label: 'Medium Light' },
    { value: 3, label: 'Medium' },
    { value: 4, label: 'Medium Heavy' },
    { value: 5, label: 'Heavy' }
];

const FilterSection = ({ 
    filters,
    filterData,
    itemsPerPage, 
    onFilterChange, 
    onWeightChange, 
    onItemsPerPageChange, 
    onClearAllFilters, 
    onApplySuggestedFilter,
    removeFilter,
    currentYear,
    onSearch,
    showMultiplayerFilter = false
}) => {
    const [searchParams] = useSearchParams();
    
    // Initialize expanded state based on URL params
    const [isFilterExpanded, setIsFilterExpanded] = useState(() => {
        // Check if any filter parameters exist in URL
        return Array.from(searchParams.keys()).some(key => 
            [...Object.values(FILTER_TYPES).map(type => type.key),
             'minWeight', 'maxWeight', 'search', 'minPlayingTime', 
             'maxPlayingTime', 'minPlayers'].includes(key)
        );
    });

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogOptions, setDialogOptions] = useState([]);
    const [dialogLabel, setDialogLabel] = useState('');
    const [currentFilterKey, setCurrentFilterKey] = useState('');
    const [localMinPlayingTime, setLocalMinPlayingTime] = useState(filters.minPlayingTime || '');
    const [localMaxPlayingTime, setLocalMaxPlayingTime] = useState(filters.maxPlayingTime || '');
    const initialYearOptions = useMemo(() => {
        const currentYearInt = parseInt(currentYear);
        return [...Array(10)].map((_, i) => (currentYearInt - i).toString());
    }, [currentYear]);

    const [yearOptions, setYearOptions] = useState(initialYearOptions);

    useEffect(() => {
        setLocalMinPlayingTime(filters.minPlayingTime || '');
        setLocalMaxPlayingTime(filters.maxPlayingTime || '');
    }, [filters.minPlayingTime, filters.maxPlayingTime]);

    const getWeightLabel = (value) => {
        const option = weightOptions.find(w => w.value === value);
        return option ? option.label : value;
    };

    const handleDialogOpen = (filterKey) => {
        const filterType = Object.values(FILTER_TYPES).find(type => type.key === filterKey);
        if (filterType) {
            setDialogOptions(filterData[filterKey] || []);
            setDialogLabel(filterType.label);
            setCurrentFilterKey(filterKey);
            setDialogOpen(true);
        }
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleOptionSelect = (option) => {
        onFilterChange(currentFilterKey, [...filters[currentFilterKey], option]);
        handleDialogClose();
    };

    const debouncedFilterChange = useMemo(
        () => debounce((type, value) => {
            onFilterChange(type, value);
        }, 300),
        [onFilterChange]
    );

    const handlePlayingTimeChange = useCallback((type) => (event) => {
        const value = event.target.value;
        if (type === 'minPlayingTime') {
            setLocalMinPlayingTime(value);
        } else {
            setLocalMaxPlayingTime(value);
        }
        debouncedFilterChange(type, value);
    }, [debouncedFilterChange]);

    const handleYearInputChange = (event, newInputValue, reason) => {
        if (reason === 'input') {
            // Only allow numbers
            const numericInput = newInputValue.replace(/[^0-9]/g, '');
            
            if (numericInput && numericInput.length <= 4) {
                // If it's not already in the options, add it
                if (!yearOptions.includes(numericInput)) {
                    setYearOptions([...yearOptions, numericInput]);
                }
            }
        }
    };

    const handleWeightChange = (event, newValue) => {
        const [newMin, newMax] = newValue;
        const updatedMin = newMin === 1 ? null : newMin;
        const updatedMax = newMax === 5 ? null : newMax;
        onWeightChange(event, [updatedMin, updatedMax]);
    };

    return (
        <Box sx={{ mt: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Button 
                    variant="outlined" 
                    onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                >
                    {isFilterExpanded ? 'Hide Filters' : 'Show Filters'}
                </Button>
                <Button 
                    variant="outlined" 
                    onClick={onClearAllFilters}
                    startIcon={<ClearIcon />}
                >
                    Clear All Filters
                </Button>
            </Box>
            
            {isFilterExpanded && (
                <Grid container spacing={2}>
                    {/* Dynamic filter components */}
                    {Object.values(FILTER_TYPES).map(filterType => (
                        <Grid item xs={12} sm={6} md={4} key={filterType.key}>
                            <FilterAutocomplete 
                                options={filterData[filterType.key] || []}
                                popularOptions={(filterData[filterType.key] || []).slice(0, 5)}
                                label={filterType.label}
                                filterKey={filterType.key}
                                filters={filters}
                                handleFilterChange={onFilterChange}
                                removeFilter={removeFilter}
                                showBrowseAllOptions={!filterType.searchEndpoint}
                                onSearch={filterType.searchEndpoint ? onSearch(filterType.key) : undefined}
                                onDialogOpen={handleDialogOpen}
                            />
                        </Grid>
                    ))}
                    
                    <Grid item xs={12} sm={6} md={4}>
                        <FilterAutocomplete 
                            options={yearOptions}
                            label="Years" 
                            filterKey="years"
                            filters={filters}
                            handleFilterChange={onFilterChange}
                            removeFilter={removeFilter}
                            freeSolo={true}
                            onInputChange={handleYearInputChange}
                            showBrowseAllOptions={false}
                            inputProps={{
                                pattern: '[0-9]*',
                                maxLength: 4
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Min Play Time"
                                    type="number"
                                    value={localMinPlayingTime}
                                    onChange={handlePlayingTimeChange('minPlayingTime')}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Max Play Time"
                                    type="number"
                                    value={localMaxPlayingTime}
                                    onChange={handlePlayingTimeChange('maxPlayingTime')}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {showMultiplayerFilter && (
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={filters.minPlayers === 2}
                                        onChange={(e) => onFilterChange('minPlayers', e.target.checked ? 2 : null)}
                                    />
                                }
                                label="Show only officially multiplayer games (minimum 2 players)"
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Box sx={{ px: 2 }}>
                            <Typography id="weight-range-slider" gutterBottom>
                                Complexity Range: {getWeightLabel(filters.minAvgWeight || 1)} - {getWeightLabel(filters.maxAvgWeight || 5)}
                            </Typography>
                            <Slider
                                value={[filters.minAvgWeight || 1, filters.maxAvgWeight || 5]}
                                onChange={handleWeightChange}
                                valueLabelDisplay="auto"
                                valueLabelFormat={getWeightLabel}
                                step={1}
                                marks={weightOptions}
                                min={1}
                                max={5}
                                aria-labelledby="weight-range-slider"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                            <InputLabel id="items-per-page-label">Items per page</InputLabel>
                            <Select
                                labelId="items-per-page-label"
                                value={itemsPerPage}
                                onChange={onItemsPerPageChange}
                                label="Items per page"
                            >
                                <MenuItem value={10}>10 per page</MenuItem>
                                <MenuItem value={25}>25 per page</MenuItem>
                                <MenuItem value={50}>50 per page</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} mb={2}>
                        <Typography variant="h6" gutterBottom>
                            Quick Filters
                        </Typography>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                            <Button 
                                variant="outlined" 
                                onClick={() => onApplySuggestedFilter('years', currentYear.toString())}
                            >
                                Games from {currentYear}
                            </Button>
                            <Button 
                                variant="outlined"
                                onClick={() => {
                                    onFilterChange('minAvgWeight', 1);
                                    onFilterChange('maxAvgWeight', 3);
                                }}
                            >
                                Lighter Games
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            )}

            <OptionsDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                options={dialogOptions}
                label={dialogLabel}
                onOptionSelect={handleOptionSelect}
            />
        </Box>
    );
};

export default FilterSection;
import React from 'react';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { Dialog, DialogContent, IconButton, Chip, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import ScaleIcon from '@mui/icons-material/Scale';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip } from '@mui/material';
import Utils from './Utils.js';

const GameDetailsOverlay = ({ game, open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!game) return null;

  const ratingColor = game.soloRating ? Utils.getRatingColor(game.soloRating.toFixed(1)) : 'bg-gray-500 text-white';

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US').format(num);
  };

  const playtimeText = game.maxPlaytime === 0
  ? `${game.minPlaytime} min`
  : `${game.minPlaytime} - ${game.maxPlaytime} min`;

  const soloRatingTooltip = `The solo rating is a combination of the game's overall rating (${formatNumber(game.numVoters)} votes) 
  and how well it's recommended for solo play (${formatNumber(game.totalSoloVotes)} votes). 
  A game with a higher rating but fewer total votes may be ranked lower than a game with a lower rating and more votes.`;

  const sanitizeOptions = {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      '*': ['class'],
      'a': ['href', 'target', 'rel'],
      'img': ['src', 'alt']
    },
    transformTags: {
      'a': (tagName, attribs) => ({
        tagName: 'a',
        attribs: {
          ...attribs,
          target: '_blank',
          rel: 'noopener noreferrer',
          class: 'text-blue-600 hover:underline break-words'
        }
      }),
      'img': (tagName, attribs) => ({
        tagName: 'img',
        attribs: {
          ...attribs,
          class: 'max-w-full h-auto'
        }
      })
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent className="bg-white p-4 sm:p-6 relative overflow-x-hidden">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className={`flex flex-col ${isMobile ? '' : 'sm:flex-row'} items-start mb-6`}>
          <div className={`${isMobile ? 'w-full mb-4' : 'w-1/3 mr-6'}`}>
            <img src={game.image} alt={game.name} className="w-full h-auto object-contain rounded-md" />
          </div>
          <div className={`${isMobile ? 'w-full' : 'w-2/3'}`}>
            <h2 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2 pr-10 break-words">{game.name}</h2>
            <div className="flex flex-wrap items-center mb-2">
              <div className={`${ratingColor} px-2 py-1 rounded-md flex items-center mr-2 mb-2`}>
                <StarIcon fontSize="small" className="mr-1" />
                <span className="font-bold">{game.soloRating ? game.soloRating.toFixed(1) : 'N/A'}</span>
              </div>
              <div className="flex items-center text-sm text-gray-600 mr-2 mb-2">
                <PeopleIcon fontSize="small" className="mr-1" />
                <span className="whitespace-nowrap">
                  {formatNumber(game.numVoters)} voters ({formatNumber(game.totalSoloVotes)} solo)
                </span>
                <Tooltip title={soloRatingTooltip} enterTouchDelay={0} leaveTouchDelay={2000} arrow>
                  <InfoIcon fontSize="small" className="text-gray-500 cursor-help ml-1 flex-shrink-0" />
                </Tooltip>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <EmojiEventsIcon fontSize="small" className="mr-2 text-blue-600" />
              <span className="text-sm font-semibold text-blue-600">
                Solo Game Rank: #{formatNumber(game.soloRank)}
              </span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 text-sm text-gray-600">
              <div className="flex items-center">
                <CalendarTodayIcon fontSize="small" className="mr-2 flex-shrink-0" />
                <span>Year: {game.yearPublished}</span>
              </div>
              <div className="flex items-center">
                <PeopleIcon fontSize="small" className="mr-2 flex-shrink-0" />
                <span>Players: {game.minPlayers} - {game.maxPlayers}</span>
              </div>
              <div className="flex items-center">
                <AccessTimeIcon fontSize="small" className="mr-2 flex-shrink-0" />
                <span>Playtime: {playtimeText}</span>
             </div>
              <div className="flex items-center">
                <ScaleIcon fontSize="small" className="mr-2 flex-shrink-0" />
                <span>Weight: {game.avgWeight ? game.avgWeight.toFixed(1) : 'N/A'}</span>
              </div>
            </div>
            <div className="mt-4">
              <a 
                href={`https://boardgamegeek.com/boardgame/${game.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-blue-600 hover:underline flex items-center"
              >
                <LinkIcon fontSize="small" className="mr-1" />
                View on BoardGameGeek
              </a>
            </div>
          </div>
        </div>
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-800 mb-2">Description</h3>
          <div 
            className="text-sm leading-relaxed text-gray-700 break-words"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(game.description, sanitizeOptions) }}
          />
        </div>
        {game.mechanics && game.mechanics.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Mechanics</h3>
            <div className="flex flex-wrap">
              {game.mechanics.map((mechanic) => (
                <Link
                  key={mechanic.id}
                  to={`/rankings?mechanics=${encodeURIComponent(mechanic.name)}`}
                  onClick={onClose}
                >
                  <Chip label={mechanic.name} className="m-1" size={isMobile ? "small" : "medium"} />
                </Link>
              ))}
            </div>
          </div>
        )}
        {game.categories && game.categories.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Categories</h3>
            <div className="flex flex-wrap">
              {game.categories.map((category) => (
                <Link
                  key={category.id}
                  to={`/rankings?categories=${encodeURIComponent(category.name)}`}
                  onClick={onClose}
                >
                  <Chip label={category.name} className="m-1" size={isMobile ? "small" : "medium"} />
                </Link>
              ))}
            </div>
          </div>
        )}
        {game.families && game.families.length > 0 && (
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">Families</h3>
            <div className="flex flex-wrap">
              {game.families.map((family) => (
                <Link
                  key={family.id}
                  to={`/rankings?families=${encodeURIComponent(family.name)}`}
                  onClick={onClose}
                >
                  <Chip label={family.name} className="m-1" size={isMobile ? "small" : "medium"} />
                </Link>
              ))}
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default GameDetailsOverlay;
import React, { useState, useEffect } from 'react';
import AutocompleteInput from './AutocompleteInput.js';

const FilterAutocomplete = ({
    options,
    popularOptions,
    label,
    filterKey,
    filters,
    handleFilterChange,
    removeFilter,
    showBrowseAllOptions = true,
    onSearch,
    onDialogOpen,
    freeSolo = false,
    onInputChange
}) => {
    const [searchOptions, setSearchOptions] = useState(options);

    useEffect(() => {
        setSearchOptions(options);
    }, [options]);

    const handleSearch = async (inputValue) => {
        // For years with freeSolo, we want to allow the input value as an option
        if (freeSolo && filterKey === 'years') {
            const numericInput = inputValue.replace(/[^0-9]/g, '');
            if (numericInput && numericInput.length <= 4) {
                if (!searchOptions.includes(numericInput)) {
                    setSearchOptions([...searchOptions, numericInput]);
                }
                if (onInputChange) {
                    onInputChange(null, numericInput, 'input');
                }
            }
        }

        if (onSearch && !showBrowseAllOptions) {
            const results = await onSearch(inputValue);
            setSearchOptions(results);
        } else {
            // Filter options locally for mechanics and categories
            const filtered = options.filter(option =>
                option.toLowerCase().includes(inputValue.toLowerCase())
            );
            setSearchOptions(filtered);
        }
    };

    return (
        <AutocompleteInput
            options={searchOptions}
            popularOptions={popularOptions || options.slice(0, 5)}
            label={label}
            filterKey={filterKey}
            filters={filters}
            handleFilterChange={handleFilterChange}
            removeFilter={removeFilter}
            showBrowseAllOptions={showBrowseAllOptions}
            onSearch={handleSearch}
            onDialogOpen={onDialogOpen}
            freeSolo={freeSolo}
            inputProps={freeSolo ? {
                pattern: '[0-9]*',
                maxLength: 4
            } : undefined}
        />
    );
};

export default FilterAutocomplete;
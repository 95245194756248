import React, { useState, useEffect, useCallback, useRef } from "react";
import GameItem from "./GameItem.js";
import axios from 'axios';
import { Pagination, CircularProgress, Box } from '@mui/material';
import GameDetailsOverlay from "./GameDetailsOverlay.js";
import ShareButton from './ShareButton.js';

const MAX_GAMES = 500;

const GameList = ({ filters, apiUrl, showDescription, itemsPerPage = 25, 
    showPagination = true, resetPageTrigger, showShareButton = false }) => {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const latestFilters = useRef(filters);
    const activeRequest = useRef(null);
    const [selectedGame, setSelectedGame] = useState(null);
    const [overlayOpen, setOverlayOpen] = useState(false);
    const [isSharing, setIsSharing] = useState(false);
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        // Check if sharing is available
        setCanShare(
            typeof navigator.share !== 'undefined' || 
            (typeof navigator.clipboard !== 'undefined' && typeof navigator.clipboard.writeText === 'function')
        );
    }, []);

    const fetchGames = useCallback(async (pageToFetch) => {
        // If there's an active request, don't start a new one
        if (activeRequest.current) {
            return;
        }

        setLoading(true);
        setError(null);

        const axiosSource = axios.CancelToken.source();
        activeRequest.current = axiosSource;

        try {
            const response = await axios.post(apiUrl, latestFilters.current, {
                params: {
                    page: pageToFetch,
                    size: itemsPerPage
                },
                cancelToken: axiosSource.token
            });

            // Check if this is still the active request
            if (activeRequest.current === axiosSource) {
                if (response.data && response.data.content) {
                    setGames(response.data.content);
                    setTotalElements(Math.min(response.data.totalElements, MAX_GAMES));
                    setTotalPages(Math.min(Math.ceil(MAX_GAMES / itemsPerPage), response.data.totalPages));
                    setPage(pageToFetch);
                } else {
                    throw new Error('Invalid response format');
                }
            }
        } catch (error) {
            if (!axios.isCancel(error)) {
                console.error('Error fetching games:', error);
                setError('Error loading games data. Please try again later.');
                setGames([]);
            }
        } finally {
            if (activeRequest.current === axiosSource) {
                setLoading(false);
                activeRequest.current = null;
            }
        }
    }, [apiUrl, itemsPerPage]);

    useEffect(() => {
        latestFilters.current = filters;
        setPage(0);
        
        // Cancel any ongoing request
        if (activeRequest.current) {
            activeRequest.current.cancel('New filters applied');
            activeRequest.current = null;
        }

        fetchGames(0);

        // Cleanup function
        return () => {
            if (activeRequest.current) {
                activeRequest.current.cancel('Component unmounted');
                activeRequest.current = null;
            }
        };
    }, [filters, resetPageTrigger, fetchGames]);

    const handlePageChange = (event, value) => {
        fetchGames(value - 1);
    };

    const handleGameClick = (game) => {
        setSelectedGame(game);
        setOverlayOpen(true);
    };

    const handleOverlayClose = () => {
        setOverlayOpen(false);
    };

    const createShareText = (games, filters, url) => {
        const header = "Solo Sleuth - Top Solo Games";
        
        const filterLines = [
            filters.mechanics.length ? `Mechanics: ${filters.mechanics.join('; ')}` : null,
            filters.categories.length ? `Categories: ${filters.categories.join('; ')}` : null,
            filters.families.length ? `Families: ${filters.families.join('; ')}` : null,
            filters.years.length ? `Years: ${filters.years.join('; ')}` : null,
            (filters.minAvgWeight !== null || filters.maxAvgWeight !== null) ? `Weight: ${filters.minAvgWeight ?? 1} - ${filters.maxAvgWeight ?? 5}` : null,
            filters.minPlayingTime ? `Min Playing Time: ${filters.minPlayingTime} minutes` : null,
            filters.maxPlayingTime ? `Max Playing Time: ${filters.maxPlayingTime} minutes` : null,
        ].filter(Boolean);

        const filtersSection = filterLines.length ? `${filterLines.map((line) => `[${line}]`).join('\n')}\n\n` : '';

        const gamesList = games.map((game, index) => `${index + 1}. ${game.name}`).join('\n');

        const footer = `See full rankings at: ${url}`;

        return `${header}

${filtersSection}${gamesList}

${footer}`;
    };

    const handleShare = async () => {
        setIsSharing(true);
        try {
            const response = await axios.post(apiUrl, {
                ...filters,
                minAvgWeight: filters.minAvgWeight ?? 1,
                maxAvgWeight: filters.maxAvgWeight ?? 5
            }, {
                params: {
                    page: 0,
                    size: 10
                }
            });

            const top10Games = response.data.content;
            const shareText = createShareText(top10Games, filters, window.location.href);
            
            if (navigator.share) {
                try {
                    await navigator.share({                 
                        text: shareText
                    });
                    return ""
                } catch (error) {
                    return "Failed to share. Please try again."
                }
            } else if (navigator.clipboard && navigator.clipboard.writeText) {
                try {
                    await navigator.clipboard.writeText(shareText);
                    return "Copied to clipboard!"
                } catch (error) {
                    return "Failed to share. Please try again."
                }
            } else {

            }
        } catch (err) {
            return "Failed to share. Please try again."
        } finally {
            setIsSharing(false);
        }
    };

    const startIndex = page * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalElements);

    if (loading) {
        return (
            <div className="flex justify-center items-center py-8">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <div className="text-center py-8 text-xl text-red-600">{error}</div>;
    }

    if (!games || games.length === 0) {
        return <div className="text-center py-8 text-xl">No games found.</div>;
    }

    return (
        <>
            {canShare && showShareButton && <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                {<ShareButton onShare={handleShare} isLoading={isSharing} />}
            </Box>}
            
            {showPagination && totalPages > 1 && (
                <div className="flex justify-center mt-4 mb-2">
                    <Pagination
                        count={totalPages}
                        page={page + 1}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
            <ul className="game-list">
                {games.map((game, index) => (
                    <GameItem
                        key={game.id}
                        game={game}
                        showDescription={showDescription}
                        rank={startIndex + index + 1}
                        onClick={() => handleGameClick(game)}
                    />
                ))}
            </ul>
            {showPagination && totalPages > 1 && (
                <div className="flex justify-center mt-4">
                    <Pagination
                        count={totalPages}
                        page={page + 1}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            )}
            {showPagination && (
                <div className="text-center mt-4">
                    Showing {startIndex + 1}-{endIndex} of {totalElements} games
                </div>
            )}
            <GameDetailsOverlay
                game={selectedGame}
                open={overlayOpen}
                onClose={handleOverlayClose}
            />
        </>
    );
};

export default GameList;
import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box, 
  Typography, 
  CircularProgress
} from '@mui/material';
import axios from 'axios';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import SearchBar from './SearchBar.js';
import FilterSection from './FilterSection.js';
import GameList from "./GameList.js";
import { FILTER_TYPES, generateInitialFilterState, handleFilterParams } from './filterConfig.js';

const currentYear = new Date().getFullYear();

const Rankings = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filterData, setFilterData] = useState({});
    const [filters, setFilters] = useState(generateInitialFilterState());
    const [searchInputValue, setSearchInputValue] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [isLoading, setIsLoading] = useState(true);
    const [isFiltersReady, setIsFiltersReady] = useState(false);
    const [resetPageTrigger, setResetPageTrigger] = useState(0);

    useEffect(() => {
        const loadFilterData = async () => {
            setIsLoading(true);
            try {
                const responses = await Promise.all(
                    Object.values(FILTER_TYPES).map(async type => {
                        const response = await axios.get(type.endpoint);
                        return {
                            key: type.key,
                            data: type.responseTransform(response.data)
                        };
                    })
                );

                setFilterData(
                    responses.reduce((acc, { key, data }) => ({
                        ...acc,
                        [key]: data
                    }), {})
                );
            } catch (error) {
                console.error('Error loading filter data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        loadFilterData();
    }, []);


    // Update filters from URL params
    useEffect(() => {
        setIsFiltersReady(false);
        const newFilters = handleFilterParams.decode(searchParams);
        setFilters(newFilters);
        setSearchInputValue(newFilters.nameSearchTerm);
        setItemsPerPage(Number(searchParams.get('itemsPerPage') || 25));
        setIsFiltersReady(true);
    }, [searchParams]);

    // Update URL params from filters
    const updateSearchParams = useCallback((newFilters, newItemsPerPage = itemsPerPage) => {
        const params = handleFilterParams.encode(newFilters);
        params.set('itemsPerPage', newItemsPerPage);
        setSearchParams(params, { replace: true });
        setResetPageTrigger(prev => prev + 1);
    }, [itemsPerPage, setSearchParams]);

    const handleSearch = useCallback((searchTerm) => {
        const newFilters = { ...filters, nameSearchTerm: searchTerm };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    }, [filters, updateSearchParams]);

    const handleInputChange = useCallback((newValue) => {
        setSearchInputValue(newValue);
    }, []);

    const handleFilterChange = useCallback((type, value) => {
        const newFilters = { ...filters, [type]: value };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    }, [filters, updateSearchParams]);

    const handleWeightChange = useCallback((event, newValue) => {
        const [min, max] = newValue;
        const newFilters = {
            ...filters,
            minAvgWeight: min === 1 ? null : min,
            maxAvgWeight: max === 5 ? null : max
        };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    }, [filters, updateSearchParams]);

    const handleFilterSearch = (filterKey) => async (searchTerm) => {
        const filterType = Object.values(FILTER_TYPES).find(type => type.key === filterKey);
        if (!filterType?.searchEndpoint) return [];

        try {
            const response = await axios.get(
                `${filterType.searchEndpoint}?searchTerm=${searchTerm}`
            );
            return filterType.searchTransform(response.data);
        } catch (error) {
            console.error(`Error searching ${filterKey}:`, error);
            return [];
        }
    };

    const handleClearFilters = useCallback(() => {
        const newFilters = generateInitialFilterState();
        setFilters(newFilters);
        setSearchInputValue('');
        updateSearchParams(newFilters);
    }, [updateSearchParams]);

    const handleItemsPerPageChange = useCallback((event) => {
        const newItemsPerPage = parseInt(event.target.value);
        setItemsPerPage(newItemsPerPage);
        updateSearchParams(filters, newItemsPerPage);
    }, [filters, updateSearchParams]);

    const removeFilter = useCallback((type, value) => {
        const newFilters = {
            ...filters,
            [type]: filters[type].filter(item => item !== value)
        };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    }, [filters, updateSearchParams]);

    const handleApplySuggestedFilter = useCallback((type, value) => {
        let newFilters = { ...filters };
        
        if (type === 'years') {
            newFilters.years = [...(filters.years || []), value.toString()];
        } else if (type === 'weight') {
            newFilters = {
                ...filters,
                minAvgWeight: 1,
                maxAvgWeight: 3
            };
        }
        
        setFilters(newFilters);
        updateSearchParams(newFilters);
    }, [filters, updateSearchParams]);

    const clearSearch = useCallback(() => {
        setSearchInputValue('');
        const newFilters = { ...filters, nameSearchTerm: '' };
        setFilters(newFilters);
        updateSearchParams(newFilters);
    }, [filters, updateSearchParams]);

    return (
        <div className="container mx-auto px-4 py-8">
            <Box className="bg-white shadow-sm rounded-lg overflow-hidden mb-8 p-6">
                <h2 className="text-2xl font-semibold text-slate-800 mb-4">Solo Game Rankings</h2>
                <Typography variant="body1" component="p" className="text-gray-700 mb-4">
                    Below are Solo Sleuth's calculated rankings of the best solo games. You can filter on mechanics, 
                    categories, families, weight, playing time, and year to find the perfect game for you. Visit{' '}
                    <RouterLink to="/faq" className="text-blue-600 hover:underline">the FAQ</RouterLink> to 
                    learn more about how these rankings are calculated.
                </Typography>
            </Box>

            <SearchBar
                searchInputValue={searchInputValue}
                onSearch={handleSearch}
                onInputChange={handleInputChange}
                onClearSearch={clearSearch}
            />

            {!isLoading && (
                <FilterSection
                    filters={filters}
                    filterData={filterData}
                    itemsPerPage={itemsPerPage}
                    onFilterChange={handleFilterChange}
                    onWeightChange={handleWeightChange}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    onClearAllFilters={handleClearFilters}
                    onApplySuggestedFilter={handleApplySuggestedFilter}
                    removeFilter={removeFilter}
                    currentYear={currentYear}
                    onSearch={handleFilterSearch}
                />
            )}

            {isLoading ? (
                <Box display="flex" justifyContent="center" my={4}>
                    <CircularProgress />
                </Box>
            ) : isFiltersReady ? (
                <GameList 
                    apiUrl='/api/games/solo' 
                    filters={filters} 
                    itemsPerPage={itemsPerPage}
                    showDescription={true}
                    resetPageTrigger={resetPageTrigger}
                    showShareButton={true}
                />
            ) : (
                <Box display="flex" justifyContent="center" my={4}>
                    <Typography>Applying filters...</Typography>
                </Box>
            )}
        </div>
    );
};

export default Rankings;
import React from 'react';
import sanitizeHtml from 'sanitize-html';
import StarIcon from '@mui/icons-material/Star';
import ScaleIcon from '@mui/icons-material/Scale';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import Utils from './Utils.js';

const GameItem = ({ game, showDescription = true, onClick }) => {
  const ratingColor = game.soloRating ? Utils.getRatingColor(game.soloRating.toFixed(1)) : 'bg-gray-500 text-white';

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US').format(num);
  };

  return (
    <li className="bg-white mb-4 p-4 rounded-lg shadow-md flex flex-col cursor-pointer hover:shadow-lg transition-shadow duration-200" onClick={() => onClick(game)}>      <div className="flex items-start mb-3">
        <div className="w-[100px] h-[100px] mr-4 bg-gray-200 flex justify-center items-center rounded-md flex-shrink-0">
        <img
          src={game.thumbnail}
          alt={game.name}
          className="max-w-[90px] max-h-[90px] object-contain"
          onError={(e) => {
            e.target.onerror = null;
            console.warn(`Failed to load image for ${game.name}`);
          }}
        />
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex justify-between items-start mb-2">
            <h2 className="text-lg font-bold text-gray-800 pr-2">{game.name}</h2>
            <div className="bg-blue-600 text-white px-3 py-1 rounded-md text-sm font-bold">
              #{formatNumber(game.soloRank)}
            </div>
          </div>
          <div className="flex flex-wrap items-center mb-2">
            <div className={`${ratingColor} px-2 py-1 rounded-md flex items-center mr-2 mb-1`}>
              <StarIcon fontSize="small" className="mr-1" />
              <span className="font-bold">{game.soloRating ? game.soloRating.toFixed(1) : 'N/A'}</span>
            </div>
            <div className="flex items-center text-sm text-gray-600 mr-2 mb-1">
              <PeopleIcon fontSize="small" className="mr-1" />
              <span className="whitespace-nowrap">
                {formatNumber(game.numVoters)} voters ({formatNumber(game.totalSoloVotes)} solo votes)
              </span>
            </div>
          </div>
          <div className="flex flex-wrap text-sm text-gray-600 mb-2">
            <div className="flex items-center mr-4 mb-1">
              <CalendarTodayIcon fontSize="small" className="mr-1" />
              <span>{game.yearPublished}</span>
            </div>
            <div className="flex items-center mb-1">
              <ScaleIcon fontSize="small" className="mr-1" />
              <span>Weight: {game.avgWeight ? game.avgWeight.toFixed(1) : 'N/A'}</span>
            </div>
          </div>
        </div>
      </div>
      {showDescription && (
        <p 
          className="text-sm leading-relaxed text-gray-700 mt-2 overflow-hidden line-clamp-3 [-webkit-line-clamp:3] [-webkit-box-orient:vertical] [display:-webkit-box]"
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(game.description) }}
        >
        </p>
      )}
    </li>
  );
};

export default GameItem;
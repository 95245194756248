import { TrendingUp, TrendingDown } from 'lucide-react';
import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box,
  Card, 
  CardContent, 
  Typography, 
  Link,
  Skeleton,
  Alert,
  Pagination,
  Stack
} from '@mui/material';
import { useSearchParams, Link as RouterLink } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FilterSection from './FilterSection.js';
import axios from 'axios';
import SearchBar from './SearchBar.js';
import { FILTER_TYPES, generateInitialFilterState, handleFilterParams } from './filterConfig.js';

const BGGRankings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rankings, setRankings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltersReady, setIsFiltersReady] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filters, setFilters] = useState(generateInitialFilterState());
  const [itemsPerPage, setItemsPerPage] = useState(25);
  
  const currentYear = new Date().getFullYear();

  // Load all filter data
  useEffect(() => {
    const loadFilterData = async () => {
      setIsLoading(true);
      try {
        const responses = await Promise.all(
          Object.values(FILTER_TYPES).map(async type => {
            const response = await axios.get(type.endpoint);
            return {
              key: type.key,
              data: type.responseTransform(response.data)
            };
          })
        );

        setFilterData(
          responses.reduce((acc, { key, data }) => ({
            ...acc,
            [key]: data
          }), {})
        );
      } catch (error) {
        console.error('Error loading filter data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadFilterData();
  }, []);

  // Update filters from URL params
  useEffect(() => {
    setIsFiltersReady(false);
    const newFilters = handleFilterParams.decode(searchParams);
    setFilters(newFilters);
    setSearchInputValue(newFilters.nameSearchTerm);
    setItemsPerPage(Number(searchParams.get('itemsPerPage') || 25));
    setPage(Number(searchParams.get('page') || 0));
    setIsFiltersReady(true);
  }, [searchParams]);

  // Update URL params from filters
  const updateSearchParams = useCallback((newFilters, newPage = page, newItemsPerPage = itemsPerPage) => {
    const params = handleFilterParams.encode(newFilters);
    params.set('page', newPage);
    params.set('itemsPerPage', newItemsPerPage);
    setSearchParams(params, { replace: true });
  }, [page, itemsPerPage, setSearchParams]);

  // Fetch rankings when filters or pagination changes
  const fetchRankings = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`/api/bgg-survey/groups?page=${page}&size=${itemsPerPage}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(filters),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setRankings(data.content || []);
      setTotalPages(data.totalPages || 0);
    } catch (error) {
      console.error('Error fetching rankings:', error);
      setError('Failed to load rankings. Please try again later.');
      setRankings([]);
      setTotalPages(0);
    } finally {
      setLoading(false);
    }
  }, [page, itemsPerPage, filters]);

  useEffect(() => {
    if (isFiltersReady) {
      fetchRankings();
    }
  }, [fetchRankings, isFiltersReady]);

  const handleSearch = useCallback((searchTerm) => {
    const newFilters = { ...filters, nameSearchTerm: searchTerm };
    setFilters(newFilters);
    updateSearchParams(newFilters, 0);
  }, [filters, updateSearchParams]);
  
  const handleInputChange = useCallback((newValue) => {
    setSearchInputValue(newValue);
  }, []);
  
  const handleFilterChange = useCallback((type, value) => {
    const newFilters = { ...filters, [type]: value };
    setFilters(newFilters);
    updateSearchParams(newFilters, 0);
  }, [filters, updateSearchParams]);

  const handleWeightChange = useCallback((event, newValue) => {
    const [min, max] = newValue;
    const newFilters = {
      ...filters,
      minAvgWeight: min === 1 ? null : min,
      maxAvgWeight: max === 5 ? null : max
    };
    setFilters(newFilters);
    updateSearchParams(newFilters, 0);
  }, [filters, updateSearchParams]);

  const handleFilterSearch = (filterKey) => async (searchTerm) => {
    const filterType = Object.values(FILTER_TYPES).find(type => type.key === filterKey);
    if (!filterType?.searchEndpoint) return [];

    try {
      const response = await axios.get(
        `${filterType.searchEndpoint}?searchTerm=${searchTerm}`
      );
      return filterType.searchTransform(response.data);
    } catch (error) {
      console.error(`Error searching ${filterKey}:`, error);
      return [];
    }
  };

  const handleClearFilters = useCallback(() => {
    const newFilters = generateInitialFilterState();
    setFilters(newFilters);
    setSearchInputValue('');
    updateSearchParams(newFilters, 0);
  }, [updateSearchParams]);

  const handlePageChange = useCallback((event, newPage) => {
    setPage(newPage - 1);
    updateSearchParams(filters, newPage - 1);
  }, [filters, updateSearchParams]);

  const handleItemsPerPageChange = useCallback((event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    updateSearchParams(filters, 0, newItemsPerPage);
  }, [filters, updateSearchParams]);

  const removeFilter = useCallback((type, value) => {
    const newFilters = {
      ...filters,
      [type]: filters[type].filter(item => item !== value)
    };
    setFilters(newFilters);
    updateSearchParams(newFilters, 0);
  }, [filters, updateSearchParams]);

  const handleApplySuggestedFilter = useCallback((type, value) => {
    let newFilters = { ...filters };
    
    if (type === 'years') {
      newFilters.years = [...(filters.years || []), value.toString()];
    } else if (type === 'weight') {
      newFilters = {
        ...filters,
        minAvgWeight: 1,
        maxAvgWeight: 3
      };
    }
    
    setFilters(newFilters);
    updateSearchParams(newFilters, 0);
  }, [filters, updateSearchParams]);

  const fetchBGGSuggestions = async (searchTerm) => {
    const response = await axios.post('/api/bgg-survey/groups?page=0&size=5', {
      nameSearchTerm: searchTerm
    });
    return [...new Set(response.data.content
      .flatMap(group => group.games)
      .map(game => game.name))];
  };

  const clearSearch = useCallback(() => {
    setSearchInputValue('');
    const newFilters = { ...filters, nameSearchTerm: '' };
    setFilters(newFilters);
    updateSearchParams(newFilters, 0);
  }, [filters, updateSearchParams]);

  const RankChange = ({ currentRank, lastYearRank }) => {
    if (lastYearRank === undefined || lastYearRank === null) {
      return (
        <Box sx={{ 
          display: 'inline-flex', 
          alignItems: 'center',
          ml: 1,
          bgcolor: 'success.main',
          color: 'white',
          px: 1,
          py: 0.5,
          borderRadius: 1,
          fontSize: '0.75rem',
          fontWeight: 'medium'
        }}>
          NEW
        </Box>
      );
    }
    
    const change = lastYearRank - currentRank;
    if (change === 0) return null;
    
    return (
      <Box sx={{ 
        display: 'inline-flex', 
        alignItems: 'center',
        gap: 0.5,
        ml: 1,
        color: 'white',
        backgroundColor: change > 0 ? 'success.main' : 'error.main',
        px: 1,
        py: 0.5,
        borderRadius: 1,
        fontSize: '0.75rem',
        fontWeight: 'medium'
      }}>
        {change > 0 ? (
          <TrendingUp size={14} />
        ) : (
          <TrendingDown size={14} />
        )}
        <span>{change > 0 ? `+${change}` : change}</span>
      </Box>
    );
  };

  const GameCard = ({ game }) => (
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: 2, 
      p: 2,
      '&:hover': {
        bgcolor: 'rgba(0, 0, 0, 0.04)'
      }
    }}>
      <Box
        component="img"
        src={game.thumbnail}
        alt={game.name}
        sx={{
          width: 64,
          height: 64,
          objectFit: 'contain',
          borderRadius: 1,
          boxShadow: 1
        }}
      />
      <Typography 
        variant="h6" 
        sx={{ 
          flex: 1,
          fontSize: '1rem',
          fontWeight: 500
        }}
      >
        {game.name}
      </Typography>
      <Link
        href={`https://boardgamegeek.com/boardgame/${game.id}`}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 0.5,
          color: 'primary.main',
          textDecoration: 'none',
          px: 2,
          py: 1,
          borderRadius: 1,
          transition: 'all 0.2s ease',
          '&:hover': {
            bgcolor: 'primary.main',
            color: 'white'
          }
        }}
      >
        BGG Page
        <OpenInNewIcon sx={{ fontSize: 16 }} />
      </Link>
    </Box>
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <Box className="bg-white shadow-sm rounded-lg overflow-hidden mb-8 p-6">
        <h2 className="text-2xl font-semibold text-slate-800 mb-4">
          2024 People's Choice Top Solo Games
        </h2>
        <Typography variant="body1" component="p" className="text-gray-700 mb-4">
          These rankings show the results of <a href="https://boardgamegeek.com/geeklist/345687/2024-peoples-choice-top-200-solo-games-200-21" className="text-blue-600 hover:underline">BoardGameGeek's 2024 People's Choice Top 200 Solo Games</a>.<br /><br />
          As part of the survey, similar games were grouped together and those groupings are shown below. If filters are applied, only games in the groups that match those filters will be shown.<br /><br />
          This website is not involved in running the survey and any questions or comments about it should be directed to the link above.<br />
          <RouterLink to="/rankings" className="text-blue-600 hover:underline">Click here</RouterLink> to see Solo Sleuth's calculated solo game rankings instead.
        </Typography>
      </Box>

      <SearchBar
        searchInputValue={searchInputValue}
        onSearch={handleSearch}
        onInputChange={handleInputChange}
        onClearSearch={clearSearch}
        fetchSuggestions={fetchBGGSuggestions}
      />

      {!isLoading && (
        <FilterSection
          filters={filters}
          filterData={filterData}
          itemsPerPage={itemsPerPage}
          onFilterChange={handleFilterChange}
          onWeightChange={handleWeightChange}
          onItemsPerPageChange={handleItemsPerPageChange}
          onClearAllFilters={handleClearFilters}
          onApplySuggestedFilter={handleApplySuggestedFilter}
          removeFilter={removeFilter}
          currentYear={currentYear}
          onSearch={handleFilterSearch}
          showMultiplayerFilter={true}
        />
      )}

      <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        {loading ? (
          Array.from({ length: 5 }).map((_, i) => (
            <Skeleton key={i} variant="rectangular" height={160} />
          ))
        ) : rankings.length === 0 ? (
          <Alert severity="info">
            No results found. Try adjusting your filters.
          </Alert>
        ) : (
          <>
            {rankings.map((group) => (
              <Card 
                key={`group-${group.groupId}`}
                sx={{ 
                  overflow: 'hidden',
                  bgcolor: 'background.paper',
                  boxShadow: 1,
                  '&:hover': {
                    boxShadow: 2
                  },
                  transition: 'box-shadow 0.2s ease'
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  <Box sx={{ 
                    bgcolor: 'primary.main',
                    color: 'primary.contrastText',
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}>
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'center',
                      minWidth: 0
                    }}>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        mr: 'auto'
                      }}>
                        <Typography 
                          component="span"
                          sx={{ 
                            fontWeight: 600,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          Rank #{group.rank}
                        </Typography>
                        <RankChange 
                          currentRank={group.rank} 
                          lastYearRank={group.lastYearRank}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 2, 
                      alignItems: 'center',
                      ml: 2,
                      whiteSpace: 'nowrap'
                    }}>
                      <Typography variant="body2">
                        Total Votes: {group.numVotes?.toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        #1 Votes: {group.numOneVotes?.toLocaleString()}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    {group.games.map((game) => (
                      <GameCard key={game.id} game={game} />
                    ))}
                  </Box>
                </CardContent>
              </Card>
            ))}
            <Stack spacing={2} alignItems="center" sx={{ mt: 4 }}>
              <Pagination 
                count={totalPages}
                page={page + 1}
                onChange={handlePageChange}
                color="primary"
                size="large"
              />
            </Stack>
          </>
        )}
      </Box>
    </div>
  );
};

export default BGGRankings;